.node {
  cursor: pointer;
}

.node.hovered circle{
  fill: red !important;
}

.node circle {
  fill: #fff;
  stroke: black;
  stroke-width: 1px;
}

.node text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 10px;
  color: #333333;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 7px;
}


