.App {
  width: 100%;
  min-width: 600px;
  margin: 10px auto;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.Header {
  background-color: #fffcfc;
  min-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgb(91, 91, 91);
  padding: 0 16px;
}

.Header h1 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  margin: 6px 0;
  padding: 0;
}

.controlsRow {
  display: flex;
  padding: 0 16px;
  background-color: #fffcfc;
  justify-content: space-between;
}

.visualization-filters {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 16px;
  background-color: #fffcfc;
}

.filter {
  display: flex;
  align-items: center;
  color: #858585;
  margin-right: 6px;
}

.filter select {
  margin-left: 6px;
  margin-right: 6px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px #e7e7e7;
}

.filter span, .filter i {
  font-size: 13px;
  font-weight: 600;
}

main {
  background-color: white;
  min-height: 600px;
}

.tooltip-trigger {
  cursor: pointer;
  display: inline-block;
}

.filter-description {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  font-size: 12px;
  padding: 15px;
  z-index: 1000;
  max-width: 300px;
}

main {
  padding: 10px 16px;
  min-height: 600px;
}

svg {
  /* outline: 1px solid green; */
}

.table-container {
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #ccc;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  font-size: 14px;
  line-height: 1;
}

.table-container th, .table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-container th {
  cursor: pointer;
  background-color: #f9f9f9;
}

.table-container th:hover {
  background-color: #f1f1f1;
}

.table-container table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pdf-container {
  padding: 10px 0px;
  height: 100vh;
  max-height: 600px; 
}

footer {
  background-color: #fffcfc;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  color: rgb(91, 91, 91);
  font-size: 12px;
  line-height: 1.1;
}
