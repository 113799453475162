.visualization-selector {
  display: inline-flex;
  justify-content: start;
  padding: 2px;
  margin: 0;
  background-color: #f4f4f4;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #e7e7e7;
  /* outline: 1px solid red; */
}

.visualization-option {
  display: flex;
  align-items: center;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  padding: 5px 10px;
  margin-right: 1px;
  cursor: pointer;
  color: #858585;
  font-weight: 500;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.visualization-option span {
  margin-left: 6px;
}

.visualization-option:last-child {
  margin-right: 0;
}

.visualization-option.active {
  background-color: #dbe5f0;
  color: #1d3d63;
}

.visualization-option:hover {
  background-color: #e2e2e2;
}
